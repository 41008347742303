import "./home.css"



export const Home = () => {
	return <>

		<br></br>
		<br></br>
		<br></br>
		<img src="https://i.imgur.com/V12hVK4.png" alt="kjcodinglogo" className="home__logo"/>
		<br></br>
		<br></br>
		<br></br>
		<br></br>
		<br></br>
		<br></br>
		<br></br>

		{/* <div> penis</div> */}
	</>
}

